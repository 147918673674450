import {
  type CustomFunction,
  type FromAppToWidgetEventData,
  type FromWidgetToAppEventData,
} from './widgetTypes'

declare global {
  interface Window {
    teampilot: unknown
  }
}

const LOCAL_STORAGE_CHAT_OPEN_KEY = (slugId: string) =>
  `teampilot.${slugId}.chatOpen`
const LOCAL_STORAGE_CHATROOM_ID_KEY = (slugId: string) =>
  `teampilot.${slugId}.chatroomId`

const createIFrame = (options: { url: string; style?: string }) => {
  const iFrame = document.createElement('iframe')
  iFrame.setAttribute('src', options.url)
  iFrame.setAttribute('allowtransparency', 'true')
  iFrame.className = 'teampilotWidget'
  if (options.style) {
    iFrame.style.cssText = options.style
  }
  iFrame.style.display = 'none'

  document.body.appendChild(iFrame)

  return iFrame
}

type ExecutableCustomFunction = CustomFunction & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  execute: (options: { input: unknown }) => Promise<{ output: any }>
}

let registeredCustomFunctions: ExecutableCustomFunction[] = []
let registeredCustomStyle: string | undefined

const createCSS = () => {
  const styles = `
    iframe.teampilotWidget {
      border: none;
      bottom: 20px;
      right: 20px;
      width: 440px;
      height: 620px;
      position: fixed;
      z-index: 99999;
      border-radius: 12px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
      @media (max-width: 440px) {
        bottom: 0;
        right: 0;
        width: 100vw;
        border-radius: 0;
        height: 100dvh;
      }
      pointer-events:all;
      background: black;
    }
  `

  const styleSheet = document.createElement('style')
  styleSheet.innerText = styles
  return styleSheet
}

const bubbleInnerHTML = (params: {
  slugIdFromScriptTag: string
  iconColor: string
  iconBg: string
}) => `
  <a data-teampilot-widget="chat" 
     data-launchpad-slug="${params.slugIdFromScriptTag}" 
     style="display: flex; 
            align-items: center; 
            justify-content: center; 
            position: fixed; 
            bottom: 20px; 
            right: 20px; 
            width: 48px; 
            height: 48px;
            z-index: 99;
            cursor: pointer;">
    
    <svg width="48" height="48" viewBox="0 0 830 829" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M580.421 511.094C548.992 580.302 479.271 628.431 398.309 628.431C358.778 628.431 321.927 616.957 290.905 597.158" stroke="${params.iconBg}" stroke-width="124.166" stroke-linecap="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M589.655 401.527C592.495 401.714 595.36 401.809 598.248 401.809C601.149 401.809 604.027 401.713 606.88 401.525C611.115 337.066 662.646 285.524 727.1 281.27C727.286 278.429 727.381 275.564 727.381 272.676C727.381 269.774 727.285 266.894 727.097 264.04C662.659 259.786 611.137 208.265 606.882 143.827C604.029 143.639 601.15 143.543 598.248 143.543C595.36 143.543 592.493 143.638 589.652 143.824C585.397 208.276 533.856 259.806 469.399 264.042C469.211 266.896 469.115 269.775 469.115 272.676C469.115 275.563 469.21 278.428 469.397 281.267C533.868 285.503 585.42 337.055 589.655 401.527Z" fill="${params.iconBg}"/>
      <circle cx="237.511" cy="272.613" r="100.575" transform="rotate(-90 237.511 272.613)" fill="${params.iconBg}"/>
      <path d="M19.986 131.657C28.8511 71.7994 72.1949 25.9259 132.064 17.1374C191.665 8.38819 282.956 0.113567 415.068 0.113281C560.173 0.112967 656.032 10.0952 714.747 19.7248C764.883 27.9476 802.087 64.225 810.288 114.365C819.683 171.803 829.162 266.384 829.162 414.208C829.162 565.713 819.205 661.29 809.586 718.273C801.549 765.889 766.693 800.526 719.077 808.565C661.819 818.233 565.964 828.302 415.068 828.302C277.714 828.302 185.965 819.959 127.419 811.17C70.1077 802.567 29.3366 758.544 20.6577 701.243C11.0059 637.519 0.973633 540.829 0.973633 414.208C0.973633 290.522 10.5462 195.395 19.986 131.657Z" fill="${params.iconBg}"/>
      <path d="M574.211 512.867C542.782 582.075 473.061 630.204 392.099 630.204C352.568 630.204 315.717 618.73 284.695 598.931" stroke="${params.iconColor}" stroke-width="124.166" stroke-linecap="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M583.389 403.32C586.229 403.507 589.095 403.602 591.982 403.602C594.883 403.602 597.761 403.506 600.614 403.318C604.849 338.859 656.381 287.316 720.834 283.063C721.021 280.222 721.115 277.357 721.115 274.469C721.115 271.567 721.02 268.687 720.831 265.833C656.393 261.579 604.872 210.058 600.617 145.62C597.763 145.432 594.884 145.336 591.982 145.336C589.094 145.336 586.227 145.431 583.386 145.617C579.132 210.069 527.59 261.599 463.134 265.835C462.945 268.689 462.85 271.567 462.85 274.469C462.85 277.356 462.944 280.221 463.131 283.06C527.603 287.296 579.154 338.848 583.389 403.32Z" fill="${params.iconColor}"/>
      <circle cx="231.247" cy="274.511" r="100.575" transform="rotate(-90 231.247 274.511)" fill="${params.iconColor}"/>
    </svg>
  </a>
`

const enrichWebsite =
  (currentScriptTag: HTMLOrSVGScriptElement | null) => () => {
    const slugIdFromScriptTag = currentScriptTag?.dataset.launchpadSlugId
    const hideBubble = currentScriptTag?.dataset.hideBubble
    const iconBg = currentScriptTag?.dataset.iconBg
    const iconColor = currentScriptTag?.dataset.iconColor
    const rememberChatroom =
      currentScriptTag?.dataset.rememberChatroom ?? 'true'
    const primaryColor = currentScriptTag?.dataset.primaryColor ?? undefined
    const openAfterReload = currentScriptTag?.dataset.openAfterReload ?? 'true'
    const iframeStyle = currentScriptTag?.dataset.iframeStyle ?? undefined
    const customStyle = currentScriptTag?.dataset.customStyle ?? undefined
    if (customStyle) {
      registeredCustomStyle = customStyle
    }

    if (!slugIdFromScriptTag) {
      console.error(
        "The 'data-launchpad-slug-id' attribute is missing in the script tag."
      )
      return
    }

    // Create iFrame's CSS
    const styleSheet = createCSS()
    document.head.appendChild(styleSheet)

    const chatroomIdFromLocalStorage = localStorage.getItem(
      LOCAL_STORAGE_CHATROOM_ID_KEY(slugIdFromScriptTag)
    )

    const searchParams = primaryColor
      ? `?primaryColor=${encodeURIComponent(primaryColor)}`
      : ''
    const launchUrl = `${process.env.NEXT_PUBLIC_BASE_URL}/widget/launch/${slugIdFromScriptTag}${searchParams}`
    const chatroomUrl = `${process.env.NEXT_PUBLIC_BASE_URL}/widget/chat/${chatroomIdFromLocalStorage}${searchParams}`
    let url = launchUrl
    if (
      chatroomIdFromLocalStorage &&
      rememberChatroom?.toLowerCase() === 'true'
    ) {
      url = chatroomUrl
    }

    // When we load the iFrame an empty chat is instantiated. We don't want that on every page load.
    // So we create the iFrame only when the user clicks on the bubble.
    let iFrame: HTMLIFrameElement | null = null
    let chatroomLoaded = false

    const postEventToIFrame = (data: FromWidgetToAppEventData) => {
      if (!iFrame) {
        throw new Error('iFrame is not defined')
      }

      iFrame.contentWindow?.postMessage({ teampilot: data }, '*')
    }

    const sendRegisterFunctionsToIFrame = () => {
      for (const fn of registeredCustomFunctions) {
        postEventToIFrame({
          event: 'registerFunction',
          function: {
            ...fn,
            execute: undefined,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } as any,
        })
      }
    }
    const sendRegisteredCustomStyle = () => {
      postEventToIFrame({
        event: 'customStyle',
        style: registeredCustomStyle ?? '',
      })
    }

    let listeners: {
      event: string
      callback: (options: FromAppToWidgetEventData) => void
    }[] = []

    const teampilot = {
      showChat: () => {
        if (!iFrame) {
          iFrame = createIFrame({ url, style: iframeStyle })
        }
        iFrame.style.display = 'unset'
        if (openAfterReload?.toLowerCase() === 'true') {
          localStorage.setItem(
            LOCAL_STORAGE_CHAT_OPEN_KEY(slugIdFromScriptTag),
            'true'
          )
        }
      },
      hideChat: () => {
        if (!!iFrame) {
          iFrame.style.display = 'none'
        }
        if (openAfterReload?.toLowerCase() === 'true') {
          localStorage.setItem(
            LOCAL_STORAGE_CHAT_OPEN_KEY(slugIdFromScriptTag),
            'false'
          )
        }
      },
      waitForChatroomLoaded: () => {
        return new Promise<void>((res) => {
          if (chatroomLoaded) {
            res()
            return
          }

          const newChatListener = () => {
            teampilot.off('chatroomLoaded', newChatListener)
            res()
          }
          teampilot.on('chatroomLoaded', newChatListener)
        })
      },

      sendMessage: async (options: { message: string }) => {
        teampilot.showChat()
        await teampilot.waitForChatroomLoaded()
        sendRegisterFunctionsToIFrame()
        sendRegisteredCustomStyle()
        await new Promise((res) => setTimeout(res, 0))
        postEventToIFrame({
          event: 'chatMessage',
          chatMessage: options.message,
        })
      },

      registerFunction: async (fn: ExecutableCustomFunction) => {
        registeredCustomFunctions = registeredCustomFunctions.filter(
          (f) => f.nameForAI !== fn.nameForAI
        )
        registeredCustomFunctions = [...registeredCustomFunctions, fn]
        // console.log('registeredCustomFunctions', registeredCustomFunctions)
        try {
          sendRegisterFunctionsToIFrame()
        } catch (error) {
          // Probably the iFrame is not loaded yet
          // It will be sent when the iFrame is loaded
        }
      },

      unregisterFunction: async (name: string) => {
        registeredCustomFunctions = registeredCustomFunctions.filter(
          (f) => f.nameForAI !== name
        )
        try {
          postEventToIFrame({
            event: 'unregisterFunction',
            name,
          })
        } catch (error) {
          // Probably the iFrame is not loaded yet
          // It will be sent when the iFrame is loaded
        }
      },

      createNewChatroom: () => {
        if (!iFrame) {
          createIFrame({ url: launchUrl, style: iframeStyle })
          return
        }
        iFrame.setAttribute('src', launchUrl)
      },

      on: (
        event: FromAppToWidgetEventData['event'],
        callback: (options: FromAppToWidgetEventData) => void
      ) => {
        listeners.push({ event, callback })
      },

      off: (
        event: FromAppToWidgetEventData['event'],
        callback: (options: FromAppToWidgetEventData) => void
      ) => {
        listeners = listeners.filter(
          (listener) =>
            !(listener.event === event && listener.callback === callback)
        )
      },

      setCustomStyle: (options: { style: string }) => {
        registeredCustomStyle = options.style
        sendRegisteredCustomStyle()
      },
    }

    const widgetListenersHandler = (data: FromAppToWidgetEventData) => {
      for (const listener of listeners) {
        if (listener.event === data.event) {
          listener.callback(data)
        }
      }
    }

    const navigationHandler = (data: FromWidgetToAppEventData) => {
      console.log('navigationHandlerData', data)
      if (data.event !== 'navigate') return
      // set window accordingly
      if (data.target === '_blank') window.open(data.url, '_blank')
      else {
        window.location.href = data.url
      }
    }

    const chatroomLoadedHandler = (data: FromAppToWidgetEventData) => {
      if (data.event !== 'chatroomLoaded') return
      if (rememberChatroom?.toLowerCase() !== 'true') return

      chatroomLoaded = true
      sendRegisterFunctionsToIFrame()
      sendRegisteredCustomStyle()

      localStorage.setItem(
        LOCAL_STORAGE_CHATROOM_ID_KEY(slugIdFromScriptTag),
        data.chatroomId
      )
    }

    const newChatHandler = (data: FromAppToWidgetEventData) => {
      if (data.event !== 'newChat') return
      teampilot.createNewChatroom()
    }

    const closeHandler = (data: FromAppToWidgetEventData) => {
      if (data.event !== 'closeChat') return
      teampilot.hideChat()
    }

    const executeFunctionHandler = async (data: FromAppToWidgetEventData) => {
      if (data.event !== 'executeFunction') return
      const customFunction = registeredCustomFunctions.find(
        (fn) => fn.nameForAI === data.name
      )
      if (!customFunction) {
        postEventToIFrame({
          event: 'functionResult',
          name: data.name,
          error: JSON.stringify(
            `Function ${data.name} not found in Widget`,
            null,
            2
          ),
        })
        return
      }

      try {
        const response = await customFunction.execute({ input: data.input })
        const output = JSON.stringify(response?.output || 'Done', null, 2)
        postEventToIFrame({
          event: 'functionResult',
          name: data.name,
          output,
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        const error = JSON.stringify(
          err?.message ?? err?.toString() ?? 'Unknown error',
          null,
          2
        )
        postEventToIFrame({
          event: 'functionResult',
          name: data.name,
          error,
        })
      }
    }

    window.addEventListener('message', (event) => {
      const data = event.data?.teampilot
      if (!data?.event) return

      widgetListenersHandler(data)
      chatroomLoadedHandler(data)
      newChatHandler(data)
      closeHandler(data)
      executeFunctionHandler(data)
      navigationHandler(data)
    })

    window.teampilot = teampilot

    // Create the bubble
    if (!hideBubble) {
      const bubbleDiv = document.createElement('div')

      bubbleDiv.innerHTML = bubbleInnerHTML({
        slugIdFromScriptTag,
        iconBg: iconBg ?? '#F55E00',
        iconColor: iconColor ?? '#FFFFFF',
      })
      bubbleDiv.addEventListener('click', () => {
        teampilot.showChat()
      })

      document.body.appendChild(bubbleDiv)
    }

    if (
      openAfterReload?.toLowerCase() === 'true' &&
      localStorage.getItem(LOCAL_STORAGE_CHAT_OPEN_KEY(slugIdFromScriptTag)) ===
        'true'
    ) {
      teampilot.showChat()
    }
  }

enrichWebsite(document.currentScript)()

export {}
